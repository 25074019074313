<template>
  <div
    id="purchaseConfirmationModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="purchaseConfirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="purchaseConfirmationModalLabel"
            class="modal-title"
          >
            Confirm Purchase
          </h5>
          <button
            ref="closeModal2"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
        <div class="modal-body">
          <p>Are you certain that you wish to proceed with the purchase of this NFT?</p>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="isLoading"
            style="min-width: 120px;"
            @click="confirmPurchase"
          >
            <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
            <span v-else>Confirm</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain } from "opensea-js";
import {useToast} from 'vue-toast-notification';
import { getContractAddressByItemId } from '@/utils/contractUtils.js';
import { createRaribleSdk } from "@rarible/sdk";
import { toItemId, toOrderId } from "@rarible/types";
import NFTMarketplaceABI from '@/contracts/NFTMarketplaceABI.json';
import FighterERC721ABI from '@/contracts/FighterERC721ABI.json';

export default {
    props: {
        showModal: Boolean,
        product: {},
        type: "fighter",
    },
    data() {
        return {
            isLoading: false, 
        };
    },
    methods: {

        closeModal() {
            this.$refs.closeModal2.click();
        },
        // handlePurchaseSuccess() {
        //   console.log("purchaseSuccess");
        //   this.$emit('purchaseSuccess', true);
        // },
        async confirmPurchase() {
            if (!window.web3) {
                console.error('Web3 is not initialized');
                return;
            }

            try {
                this.isLoading = true;
                const store = useStore();
                const accountAddress = store.getWalletAddress;
                //const royaltyInfo = await getRoyaltyInfo(contractAddress); // Replace with your contract address
                //const prices = calculatePrices(this.maticSalePrice, royaltyInfo.royalty_fee); // Replace with your utility function

                if (this.maticSalePrice === -1) {
                    return;
                }

                var tokenAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS; // Update this based on your product data
                var tokenId = 0; 
                if(this.type == 'item'){
                    tokenAddress = getContractAddressByItemId(parseInt(this.product.itemId) );
                    if(this.product.token_id >= 20000){
                      tokenAddress = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
                    }
                    else if(this.product.token_id >= 10000){
                      tokenAddress = process.env.VITE_STONE_CONTRACT_ADDRESS;
                    }
                    tokenId = this.product.token_id;
                }else{
                    tokenId = this.product.properties.token_id;
                }

                // Create the Seaport instance
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner();
                // const apiKey = process.env.VITE_RARIBLE_API_KEY; 
                // const raribleSdk = createRaribleSdk(signer, "testnet", { apiKey });

                // const openseaSDK = new OpenSeaSDK(provider, {
                //     chain: Chain.Polygon,
                //     apiKey: process.env.VITE_OPENSEA_API_KEY,
                // });

                // Replace X with contract address and Y with tokenId
                // var order = "";
                // var price = 0;

                // if(this.type == 'item'){
                //   const item = await raribleSdk.apis.item.getItemById({ itemId: toItemId(`KROMA:${tokenAddress}:${tokenId}`) });
                //   order = toOrderId(item.bestSellOrder.id);
                //   price = item.bestSellOrder.makePrice;
                // }
                // else{
                //   const fighterApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byItem?type=LIST&itemId=KROMA:${tokenAddress}:${tokenId}&size=1&sort=LATEST_FIRST`;
                //   const fighterResponse = await axios.get(fighterApiUrl, {
                //       headers: {
                //           'accept': 'application/json',
                //           'x-api-key': process.env.VITE_RARIBLE_API_KEY
                //       }
                //   });
                //   order = `KROMA:${fighterResponse.data.activities[0].hash}`;
                //   price = fighterResponse.data.activities[0].price;
                // }

                // const item = await raribleSdk.apis.item.getItemById({ itemId: toItemId(`KROMA:${tokenAddress}:${tokenId}`) });
                // console.log("item purchase ", item);
                const itemResponse = await axios.get(`https://reward-portal.kryptofighters.io/api/marketplace-testnet/listing-status?contractAddress=${tokenAddress}&tokenId=${tokenId}`);

                console.log('itemResponse ',itemResponse);

                if(itemResponse.data.currencyAddress == process.env.VITE_JAB_CONTRACT_ADDRESS && store.getWalletBalance.jab < Number(itemResponse.data.price)){
                  this.isLoading = false;
                  this.closeModal();
                  useToast().open({
                          message: 'Error: JAB is not enough',
                          position: 'top-right',
                          type: 'error',
                      });
                  return;
                }

                const buyRequest = {
                  nftContract: itemResponse.data.nftContract,
                  tokenId: tokenId, // amount of NFTs to sell
                };

                console.log("buyRequest:", buyRequest);

                const marketplaceAddress = '0x2B72BCd864025b7DFf9c0b036e955D1099Fa8088';

                console.log('marketplaceAddress'+marketplaceAddress);

                const marketplaceContract = new ethers.Contract(marketplaceAddress, NFTMarketplaceABI, signer);


                // Check Allowance
                const allowance = await tokenContract.allowance(await signer.getAddress(), process.env.VITE_MARKETPLACE_LISTING_ADDRESS);
                if (ethers.BigNumber.from(allowance).lt(ethers.BigNumber.from(price))) {
                    console.log("Approving marketplace to spend tokens...");
                    const approveTx = await tokenContract.approve(process.env.VITE_MARKETPLACE_LISTING_ADDRESS, price);
                    await approveTx.wait();
                    console.log("Token approved!");
                }

                const nftContract = new ethers.Contract(
                    process.env.VITE_FIGHTER_CONTRACT_ADDRESS,
                    FighterERC721ABI, 
                    signer 
                );
                
                const approvedAddress = await nftContract.getApproved(tokenId);
                if (approvedAddress.toLowerCase() !== process.env.VITE_MARKETPLACE_LISTING_ADDRESS.toLowerCase()) {
                    console.log("Approving marketplace for NFT...");
                    const approveTx = await nftContract.approve(process.env.VITE_MARKETPLACE_LISTING_ADDRESS, tokenId);
                    await approveTx.wait();
                    console.log("NFT approved!");
                }
                console.log('marketplaceAddress',nftContract);

                // Check if the token is already approved
                /*const approvedAddress = await nftContract.getApproved(tokenId);
                console.log('marketplaceAddress',approvedAddress);
                if (approvedAddress.toLowerCase() !== marketplaceAddress.toLowerCase()) {
                    console.log("Approving marketplace...");
                    const approveTx = await nftContract.approve(marketplaceAddress, tokenId);
                    await approveTx.wait();
                    console.log("Marketplace approved!");
                }*/



                //console.log('tokenId',tokenId);
                //console.log('Fighter Contract',process.env.VITE_FIGHTER_CONTRACT_ADDRESS);
                // Call the listNFT function
                const tx = await marketplaceContract.buyNFT(
                    process.env.VITE_FIGHTER_CONTRACT_ADDRESS,
                    tokenId
                );
                const receipt = await tx.wait();
                console.log("NFT listed successfully:", receipt.transactionHash);

                // const item = await sdk.apis.item.getItemById({ itemId: toItemId("ETHEREUM:X:Y") })
                
                // var order = '';
                // if(this.type == 'item' && parseInt(this.product.itemId)< 5){
                //     // order = await openseaSDK.api.getOrder({
                //     //     orderHash: this.product.orderHash
                //     // })

                //     order = await raribleSdk.order.buy(buyRequest);
                // }else{
                //     // order = await openseaSDK.api.getOrder({
                //     //     side: "ask", 
                //     //     assetContractAddress: tokenAddress,
                //     //     tokenIds: [tokenId],
                //     // })
                //     order = await raribleSdk.order.buy(buyRequest);
                // }
                // // console.log("accountAddress" , accountAddress);
                // console.log("order" , order);
                // const transactionHash = await openseaSDK.fulfillOrder({ order, accountAddress })
                // console.log("transactionHash" + transactionHash);
                this.isLoading = false;

                useToast().open({
                        message: 'Successfully purchased.',
                        position: 'top-right',
                        type: 'success',
                    });

                this.closeModal();
            } catch (error) {
                this.isLoading = false;
                this.closeModal();
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                //this.$refs.errorToast.showError('Error: ' + error.message);
                console.error('Error confirming purchase:', error);
                
            }
        },
    },
};
</script>
