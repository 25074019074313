<template>
  <div class="page-content content-wrap">
    <!--<img class="content-bg" src="/src/assets/backgrounds/background-default.png" alt="">-->
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <div class="collection-info">
        <div class="d-flex align-items-left">
          <img
          :src="require('@/assets/Icon-stats-fighters.png')"
            alt="NFT Icon"
            class="header-icon mr-3"
          >
          <h1>Fighters</h1>
        </div>
        <div class="text-secondary">
          The Fighter is represented as a NFT character, a unique digital asset that can
          be
          <br>battled, coach, collected, and even used to earn resources & collectibles.
        </div>
        <div class="d-flex justify-content-between align-items-center default-row flex-wrap">
          <!-- <div v-if="true">
            <h4> {{ total_list }}/{{ total_supply }} Fighters </h4>
          </div> -->
          <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div class="mr-md-3 mt-1">
              <button 
                class="btn btn-secondary dropdown-filter-toggle" 
                data-bs-toggle="modal"
                data-bs-target="#filterPopup"
                @click="showModal = true"
                :disabled="false">
                <span class="button-module_icon-wrapper__VV4f-">
                  <svg class="button-module_icon__-nXel" viewBox="0 0 24 24" width="16" height="16" style="fill: currentcolor;">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5.75h6.354a2.751 2.751 0 0 0 5.293 0H21a.75.75 0 0 0 0-1.5h-6.354a2.751 2.751 0 0 0-5.292 0H3a.75.75 0 0 0 0 1.5Zm14 3.5a2.75 2.75 0 1 0 2.646 3.5H21a.75.75 0 0 0 0-1.5h-1.354a2.751 2.751 0 0 0-2.646-2ZM2.25 12a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75Zm2.104 7.75H3a.75.75 0 0 1 0-1.5h1.354a2.751 2.751 0 1 1 0 1.5ZM11.25 19a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75Z" fill="currentColor"></path>
                  </svg>
                </span>
                <span class="AllFiltersButton_Content__OG6yt">
                  <span class="text-module_text__ChjB4 text-module_text-2-bold__QbXE- text-module_default__oQBba">
                    Filters
                  </span>
                </span>
              </button>
            </div>

            <div class="dropdown mr-md-3 mt-1 ">
              <button
                class="btn btn-secondary dropdown-toggle dropdown-filter-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Element
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="classDropdown"
              >
                <li
                  v-for="elementOption in elementOptions"
                  :key="elementOption.id"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :checked="isSelectedElement(elementOption.id)"
                    style="margin-left: 10px;"
                    @change="toggleSelectedElement(elementOption.id)"
                  >
                  <img
                    :src="getElementImageUrl(elementOption.id)"
                    alt="Product Icon"
                    class="img-fluid"
                    style="margin-left: 6px; width: 16px; height: auto;"
                  >
                  <label
                    class="form-check-label"
                    style="margin-left: 6px;"
                  >{{ elementOption.name }}</label>
                </li>
              </ul>
            </div>

            <div class="dropdown  mr-md-3  mt-1">
              <button
                class="btn btn-secondary dropdown-toggle dropdown-filter-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Class
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="classDropdown"
              >
                <li
                  v-for="classOption in classOptions"
                  :key="classOption.id"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :checked="isSelectedClass(classOption.id)"
                    style="margin-left: 10px;"
                    @change="toggleSelectedClass(classOption.id)"
                  >
                  <img
                    :src="getClassImageUrl(classOption.id)"
                    alt="Product Icon"
                    class="img-fluid"
                    style="margin-left: 6px; width: 16px; height: auto;"
                  >
                  <label
                    class="form-check-label"
                    style="margin-left: 6px;"
                  >{{ classOption.name }}</label>
                </li>
              </ul>
            </div>

            <div class="dropdown  mt-1">
              <button
                id="orderByDropdown"
                class="btn btn-secondary dropdown-toggle dropdown-filter-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ getSelectedOrderByText() }}
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="orderByDropdown"
              >
                <li
                  v-for="orderByOption in orderByOptions"
                  :key="orderByOption.id"
                >
                  <a
                    class="dropdown-item"
                    href="#"
                    @click="selectOrderBy(orderByOption.id)"
                  >{{ orderByOption.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="true">
            <h4> {{ total_list }} / {{ total_supply }} Fighters </h4>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div v-if="isLoading">
              <div style="display: flex; flex-direction: row; overflow-x: auto;">
                <div
                  v-for="index in 6"
                  :key="index"
                >
                  <v-skeleton-loader
                    :type="'card'"
                    :animation="true"
                    :width="200"
                    :height="360"
                    theme="dark"
                    style="margin-right: 24px;"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="filteredProducts.length > 0" class="product-list">
                <div v-for="(product) in filteredProducts" :key="product.id">
                  <ProductFighter v-if="product.isFighter"
                    :product="product"
                  />
                  <ProductItem v-else
                    :product="product"
                  />
                </div>
              </div>
              <div v-else>
                <img
                  src="~@/assets/no-data.png"
                  alt="no data"
                  style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FilterPopup :selectedFilter="getSelectedFilterData()" @refreshFilter="refreshFilter"/>
</template>
  
<script>
import ProductFighter from '@/components/ProductFighterItem.vue'; // Update the path based on your project structure
import ProductItem from '@/components/NftItem.vue';
import { timeAgo } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';

import Web3 from 'web3';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import {shortenedWalletAddress_rarible} from '@/utils/walletUtils.js';
import { useStore } from '@/stores/index.ts';
import FilterPopup from "@/components/popup/FilterPopup.vue";

export default {
    components: {
      ProductFighter,
      ProductItem,
      FilterPopup,
      getClassImageUrl,
        
    }, data() {
        return {
            products: [],
            filteredProducts: [],
            elementOptions: [
                { id: "EARTH", name: 'Earth' },
                { id: "FIRE", name: 'Fire' },
                { id: "WATER", name: 'Water' },
                { id: "WIND", name: 'Wind' },
                { id: "ELECTRIC", name: 'Electric' },
                // Add more class options as needed
            ],
            classOptions: [
                { id: 1, name: 'All-Rounder' },
                { id: 2, name: 'Bodyguard' },
                { id: 3, name: 'Duelist' },
                { id: 4, name: 'Stalker' },
                { id: 5, name: 'Acrobat' },
                { id: 6, name: 'Trickster' },
                { id: 7, name: 'Destroyer' },
                { id: 8, name: 'Brawler' },
                // Add more class options as needed
            ],
            selectedClasses: [],
            selectedElements: [], // Store selected class IDs
            selectedParts: [],
            selectedSockets: [],
            orderByOptions: [
                { id: 1, name: 'Latest' },
                { id: 2, name: 'Lowest Price' },
                { id: 3, name: 'Highest Price' },
            ],
            selectedOrderBy: 1,
            isLoading: true,
            total_supply: 0,
            total_list: 0,
        };
    },
    mounted() {
        this.fetchFighterData();
        this.fetchNftStats();
    },
    methods: {
        getSelectedFilterData(){
          return [{type: 'element', data: this.selectedElements},
                  {type: 'class', data: this.selectedClasses}];
        },
        getElementImageUrl(elementId){
            return getElementImageUrl(elementId);
        },
        getClassImageUrl(classId){
            return getClassImageUrl(classId);
        },
        isSelectedElement(elementId) {
            return this.selectedElements.includes(elementId);
        },
        toggleSelectedElement(elementId) {
            if (this.selectedElements.includes(elementId)) {
                this.selectedElements = this.selectedElements.filter(id => id !== elementId);
            } else {
                this.selectedElements.push(elementId);
            }

            this.filterProducts();
        },
        isSelectedClass(classId) {
            return this.selectedClasses.includes(classId);
        },
        toggleSelectedClass(classId) {
            if (this.selectedClasses.includes(classId)) {
                this.selectedClasses = this.selectedClasses.filter(id => id !== classId);
            } else {
                this.selectedClasses.push(classId);
            }

            this.filterProducts();
        },
        refreshFilter(newData){
          console.log('refreshFilter',newData);
          this.selectedElements = newData.find(e=>e.type === 'element').data;
          this.selectedClasses = newData.find(c=>c.type === 'class').data;
          this.selectedParts = newData.find(c=>c.type === 'part').data;
          this.selectedSockets = newData.find(c=>c.type === 'socket').data;
          this.filterProducts();
        },
        filterProducts() {
          console.log('this.selectedElements',this.selectedElements);
          console.log('this.selectedClasses',this.selectedClasses);
          console.log('this.selectedParts',this.selectedParts);
          console.log('this.selectedSockets',this.selectedSockets);
            this.filteredProducts = this.products.filter(product => {
                const elementFilter = this.selectedElements.length === 0 || this.selectedElements.includes(product.element_id);
                const classFilter = this.selectedClasses.length === 0 || this.selectedClasses.includes(product.class_id);
                const partFilter = this.selectedParts.length === 0 || this.selectedParts.some(p1=>p1.part_id === product.s3ProductData.data.properties.body_part_body_id) || 
                                    this.selectedParts.some(p2=>p2.part_id ===product.s3ProductData.data.properties.body_part_pant_id) || 
                                    this.selectedParts.some(p3=>p3.part_id ===product.s3ProductData.data.properties.body_part_top_face_id) || 
                                    this.selectedParts.some(p4=>p4.part_id ===product.s3ProductData.data.properties.body_part_top_id);
                const socketFilter = this.selectedSockets.length === 0 || this.selectedSockets.includes(product.s3ProductData.data.properties.spirit_socket_1) || 
                                    this.selectedSockets.includes(product.s3ProductData.data.properties.spirit_socket_2);
                // Combine both filters with logical AND
                return elementFilter && classFilter && partFilter && socketFilter;
            });
            this.applySorting();
        },
        selectOrderBy(orderById) {
            this.selectedOrderBy = orderById;
            this.applySorting();
        },
        getSelectedOrderByText() {
            const selectedOption = this.orderByOptions.find(option => option.id === this.selectedOrderBy);
            return selectedOption ? selectedOption.name : '';
        },
        applySorting() {
            if (this.selectedOrderBy == 2) {
                this.filteredProducts.sort((a, b) => {
                    return a.usdPrice - b.usdPrice;
                });

            } else if (this.selectedOrderBy == 3) {
                this.filteredProducts.sort((a, b) => {
                    return b.usdPrice - a.usdPrice;
                });
            } else {
                this.filteredProducts.sort((a, b) => {
                    return b.listDate - a.listDate;
                });
            }
        },
        async fetchNftStats(){
          try{
            const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
            const apiUrl = `https://testnet-api.rarible.org/v0.1/data/collections/KROMA:${collection}/stats?currency=ETH`;
            const response = await axios.get(apiUrl, {
                headers: {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_RARIBLE_API_KEY
                }
            });
            console.log(response);
            this.total_supply = response.data.items;
            this.total_list = response.data.listed;
            } catch (error) {
            console.error('Error fetching total supply:', error);
            }
            // const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
            // const products = [];
            // try{

            //     const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_FIGHTER_CONTRACT_ADDRESS);

            //     const totalSupply = await nftContract.methods.totalSupply().call();
            //     console.log(`Total NFTs in the contract: ${totalSupply}`);
            //     this.total_supply = totalSupply;

            // } catch (error) {
            //     console.error('Error fetching total supply:', error);
            // }
        },
        async fetchFighterData() {
            try {
                const store = useExchangeStore();
                store.fetchExchangeRates();

                const wallet = useStore().getWalletAddress;
                const fighterCollection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS; 
                // const fighterApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${fighterCollection}&size=256`;
                // const fighterApiUrl = `http://localhost:3000/api/marketplace-testnet/collection/all-listing?contractAddress=${fighterCollection}&size=50`;
                const fighterResponse = await axios.get(`https://reward-portal.kryptofighters.io/api/marketplace-testnet/collection/all-listing?contractAddress=${fighterCollection}&size=50`);

                console.log('fighterResponse ',fighterResponse);

                const filteredList = fighterResponse.data;

                // const combinedData = [...fighterResponse.data.activities];

                // const recentSell = combinedData.filter(activity => activity['@type'] == 'SELL');
                // const recentList = combinedData.filter(activity => activity['@type'] == 'LIST');
                // const cancelList = combinedData.filter(activity => activity['@type'] == 'CANCEL_LIST');

                // console.log("recentSell: " , recentSell);
                // console.log("recentList: " , recentList);

                // const sellMap = new Map();
                // recentSell.forEach(sell => {
                //   const tokenId = sell.nft.type.tokenId;
                //   const lastUpdatedAt = new Date(sell.lastUpdatedAt);
                //   if (!sellMap.has(tokenId) || new Date(sellMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
                //     sellMap.set(tokenId, sell);
                //   }
                // });

                // const cancelMap = new Map();
                // cancelList.forEach(sell => {
                //   const tokenId = sell.make.tokenId;
                //   const lastUpdatedAt = new Date(sell.lastUpdatedAt);
                //   if (!cancelMap.has(tokenId) || new Date(cancelMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
                //     cancelMap.set(tokenId, sell);
                //   }
                // });

                // // Remove items in recentList that are also in recentSell
                // const filteredList = recentList.filter(list => {
                  
                //   const tokenId = list.make.type.tokenId;
                //   const lastUpdatedAt = new Date(list.lastUpdatedAt);
                //   const sellItem = sellMap.get(tokenId);
                //   const cancelItem = cancelMap.get(tokenId);

                //   return (!sellItem  || lastUpdatedAt > new Date(sellItem.lastUpdatedAt)) && (!cancelItem || lastUpdatedAt > new Date(cancelItem.lastUpdatedAt) );
                // });

                // console.log("filteredList: " , filteredList);

                const ethToUsdExchangeRate = store.getEthToUsdExchangeRate;
                const maticToUsdExchangeRate = store.getMaticToUsdExchangeRate;

                filteredList.sort((a, b) => {  
                    const date1 = new Date(b.timestamp);
                    const date2 = new Date(a.timestamp);
                    return date1 - date2;
                });

                const latestMap = new Map();
                filteredList.forEach(item => {
                  if (!latestMap.has(item.tokenId)) {
                    latestMap.set(item.tokenId, item);
                  } else {
                    const existingItem = latestMap.get(item.tokenId);
                    if (new Date(item.timestamp) > new Date(existingItem.timestamp)) {
                    // if (Number(item.price) < Number(existingItem.price)) {
                      latestMap.set(item.tokenId, item);
                    }
                  }
                });
                const uniqueList = Array.from(latestMap.values());

                this.products = await Promise.all(
                  uniqueList.map(async (listing) => {
                    const tokenID = listing.tokenId;
                    const date = new Date(listing.timestamp);
                    const unixTimestamp = Math.floor(date.getTime() / 1000);
                    const dateString = timeAgo(unixTimestamp);
                    const price = parseFloat(listing.price);
                    const currency = ((listing.currencyAddress?? '') == '0x0000000000000000000000000000000000000000')? "ETH":"JAB";
                    const usdPrice = convertToUSD(price, currency, ethToUsdExchangeRate, maticToUsdExchangeRate);
                    const collection = listing.nftContract.toLowerCase();
                    const isFighterProduct = true;

                    let s3ProductData = {};

                    try {
                      const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${tokenID}.json`);
                      s3ProductData = response; // Get data from response
                    } catch (error) {
                      console.error('Error fetching data from S3:', error);
                    }

                    return {
                      id: tokenID,
                      price: (currency == 'ETH')? price.toFixed(3):price,
                      currency: currency,
                      listDate: listing.timestamp,
                      dateString: dateString,
                      usdPrice: usdPrice, // Use the calculated usdPrice
                      isFighter: isFighterProduct,
                      itemId: isFighterProduct ? 0 : 100,
                      s3ProductData: s3ProductData,
                      // Add other properties as needed
                      isOwner: listing.seller === wallet,
                    };
                  })
                );

                this.products.sort((a, b) => {
                  const dateA = new Date(a.listDate);
                  const dateB = new Date(b.listDate);

                  return dateB - dateA;
                });

                // for (const data of this.products) {
                //   if(data.isFighter == false){
                //     try{
                //       const apiUrl = `https://testnet-api.rarible.org/v0.1/items/KROMA:${ticketCollection}:${data.id}`;

                //       const headers = {
                //         'accept': 'application/json',
                //         'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                //       };

                      
                //       const nftResponse = await axios.get(apiUrl, {headers}  );
                //       console.log("nftResponse:", nftResponse);

                //       data.image = nftResponse.data.meta.content[0].url;
                //       data.type = nftResponse.data.meta.name;
                //       data.description = nftResponse.data.meta.description;
                //     }
                //     catch (error) {
                //         console.error(`Error fetching data for token ID ${data.id}:`, error);
                //     }
                //   }
                // }

                console.log("selectedClasses" ,this.products);

                this.filterProducts();

                //this.products = this.products.slice(0, 50);
                this.isLoading = false;
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust minmax values as per your design */
    gap: 20px;
    /* Adjust gap between grid items */
}

.product-item {
    /* Add additional styling for each grid item if needed */
}

.text-secondary {
    font-size: 16px;
    color: #A4A5A5 !important;
    font-weight: 300;
}

@media (max-width: 768px) {
    .product-list {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
}
</style>